import React,  { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contactform.css';
import {BsArrowRightShort} from 'react-icons/bs';
import {HiMail} from 'react-icons/hi';
import {TbSend} from 'react-icons/tb';
import {RiWhatsappFill, RiLinkedinBoxFill} from 'react-icons/ri';
import { Button } from './Button';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer, fatrans } from './variants';

const Contactform = () =>{
    const form = useRef();

        const sendEmail = (e) => {
            e.preventDefault();

        emailjs.sendForm('service_thxx1ia', 'template_4dgf23g', form.current, 'zirl70GOVLZ_IbSJy')

        form.current?.reset();
  };
  

return(
        <section className="contactform_section" id="contactform">
            
            <m.div 
            variants={staggerContainer(0.3, 1)}
            initial='hidden'
            whileInView={'show'}
            className="contactform__container container grid">
                <div className="contactform__content">
                    <m.h3 variants={fadeIn('down', 'spring', 0.3, 1.1)} className="contactform__title">Let's connect</m.h3>

                    <div className="contactform__info">

                        <m.div 
                        variants={fatrans('','spring', 0.4, 1.1)}
                        className="contactform__card">
                            <HiMail className="contactform__card-icon" />

                            <h3 className="contactform__card-title">Email</h3>
                            <span className="contactform__card-data">vidz123@outlook.com</span>

                            <a href="mailto:vidz123@outlook.com.com" className="contactform__button">
                                Write me{""}
                                <BsArrowRightShort className="contactform__button-icon" />
                            </a>
                        </m.div>

                        <m.div 
                        variants={fatrans('','spring', 0.5, 1.1)} 
                        className="contactform__card">
                            <RiWhatsappFill className="contactform__card-icon" />

                            <h3 className="contactform__card-title">Whatsapp</h3>
                            <span className="contactform__card-data">+94 71 641 8542</span>

                            <a href="https://wa.me/0716418542?text=howdy." 
                            className="contactform__button">
                                Book a call{""}
                                <BsArrowRightShort className="contactform__button-icon" />
                            </a>
                        </m.div>

                        <m.div
                        variants={fatrans('','spring', 0.6, 1.1)}
                        className="contactform__card">
                            <RiLinkedinBoxFill className="contactform__card-icon" />

                            <h3 className="contactform__card-title">LinkedIn</h3>
                            <span className="contactform__card-data">Vidura Lochana</span>

                            <a href="https://www.linkedin.com/in/viduralochana" target={'_blank'} rel="noopener noreferrer" className="contactform__button">
                                Find me{""}
                                <BsArrowRightShort className="contactform__button-icon" />
                            </a>
                        </m.div>
                    </div>                    
                </div>

                <div className="contactform__content">
                    <m.h3 variants={fadeIn('down', 'spring', 0.3, 1.1)} className="contactform__title">Drop me a message :)</m.h3>

                    <form ref={form} onSubmit={sendEmail} className="contactform__form">
                        <m.div
                        variants={fadeIn('left', 'spring', 0.4, 1.1)}
                        className="contactform__form-div">
                            <label className="contactform__form-tag">Name</label>
                            <input type="text" name="name" 
                            className="contactform__form-input" 
                            placeholder="your name"
                            />
                        </m.div>

                        <m.div
                        variants={fadeIn('left', 'spring', 0.5, 1.1)}
                        className="contactform__form-div">
                            <label className="contactform__form-tag">Email</label>
                            <input type="email" name="email" 
                            className="contactform__form-input" 
                            placeholder="your email"
                            />
                        </m.div>

                        <m.div
                        variants={fadeIn('left', 'spring', 0.6, 1.1)}
                        className="contactform__form-div">
                            <label className="contactform__form-tag">Mobile</label>
                            <input type="tel" name="mobile" 
                            className="contactform__form-input" 
                            placeholder="mobile number (optional)"
                            />
                        </m.div>

                        <m.div
                        variants={fadeIn('left', 'spring', 0.7, 1.1)}
                        className="contactform__form-div contactform__form-area">
                            <label className="contactform__form-tag">Message</label>
                            <textarea name="message" cols="20" rows="4"
                            className="contactform__form-input"
                            placeholder="message"
                            ></textarea>
                        </m.div>
                            <Button onClick={sendEmail} 
                            className='button button--flex' 
                            buttonStyle='btn--send' 
                            buttonSize='btn--snsize'
                            >
                                Send
                                <TbSend className="tb" />
                            </Button>
                    </form>
                </div>
                
            </m.div>
        </section>
    )
}

export default Contactform;