import React from 'react';
import '../App.css';
import './Animation.css';
import resume from '../testimonialimg/Vidura Lochana De Silva.pdf';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

document.querySelector('body').addEventListener('mousemove',eyeball);

function eyeball(){
    const eye = document.querySelectorAll('.eye');
    eye.forEach(function(eye){
        let x = (eye.getBoundingClientRect().left) + (eye.clientWidth / 2);
        let y = (eye.getBoundingClientRect().top) + (eye.clientHeight / 2);

        let radian = Math.atan2(window.event.pageX - x, window.event.pageY - y);
        let rotation = (radian * (180 / Math.PI) * -1) + 270;
        eye.style.transform = "rotate("+rotation+"deg)"
    });
}

function Animation() {
  return (
    <m.div 
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className='Animationsection-container'>
        <m.h1 variants={fadeIn('down', 'spring', 0.3, 1.1)}>What are you looking at?</m.h1>
        <m.p variants={fadeIn('down', 'spring', 0.4, 1.1)}>there's nothing here 😏</m.p>

        <m.div 
        variants={fadeIn('up', 'spring', 0.3, 1.1)}
        className='box'>
            <div className='eye'></div>
            <div className='eye'></div>
        </m.div>

        <div className='cvdownload'>
          <m.p variants={fadeIn('up', 'spring', 0.4, 1.1)}><a href={resume} download={"Vidura Lochana De Silva"}>Download</a> my resume.</m.p>
        </div>

        <m.div variants={fadeIn('up', 'spring', 0.5, 1.1)} className='mouth'></m.div>
    </m.div>
  );
}

export default Animation;