import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Crads';
import Faq from '../Faq';
import Testimonial from '../Testimonial';



function Home (){
    return(
        <>
            <HeroSection />
            <Cards />
            <Faq />
            <Testimonial />
        </>
    );
}

export default Home;