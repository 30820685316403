import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Footer from './components/Footer';
import Mywork from './components/pages/Mywork';
import Singlepost from './components/pages/Singlepost';
import Wussup from './components/pages/Wussup';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <>
    <Router>
      <ScrollToTop/>
      <Navbar />
      <Routes>
      <Route exact path="/" element={<Home />}/>
      <Route exact path="/about" element={<About />}/>
      <Route exact path="/contact" element={<Contact />}/>
      <Route exact path="/mywork" element={<Mywork />}/>
      <Route exact path="/Singlepost:slub" element={<Singlepost />}/>
      <Route exact path="/Wussup" element={<Wussup />}/>
      </Routes>
      <Footer />
    </Router>
    </>
  );
}

export default App;
