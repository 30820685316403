import React from 'react';
import '../../App.css';
import Animation from '../Animation';




function Wussup (){
    return(
        <>
            <Animation />
        </>
    );
}

export default Wussup;