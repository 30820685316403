import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

function HeroSection() {
  return (
    <m.div variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className='hero-container'>
        <video src='/videos/video-2.mp4' autoPlay loop muted />
        <m.h1 variants={fadeIn('down', 'spring', 0.3, 1.1)}>howdy.</m.h1>
        <m.p variants={fadeIn('right', 'tween', 0.4, 1.6)}>I'm Vidura Lochana, a skilled and dedicated web designer and developer. With a focus on innovative solutions and engaging user interfaces, I'm committed to delivering responsive and professional websites that meet the needs of my clients.
        </m.p>
      
        <m.div className='hero-btns'
        variants={fadeIn('up', 'tween', 0.5, 1.1)}
        >
            <Button 
            className='btns' 
            buttonStyle='btn--outline' 
            buttonSize='btn--large'
            link='/contact'
            >
                GET STARTED
            </Button>

            <Button
            className='btns' 
            buttonStyle='btn--primary' 
            buttonSize='btn--large'
            link='/mywork'
            >
                SEE MY WORK 
            </Button>
        </m.div>
    </m.div>
  );
}

export default HeroSection;