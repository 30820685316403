import React from 'react';
import Constants from '../constants/constants';
import './ProjectStyles.css';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

function Projects() {
  return (
    <m.div
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'} 
    className='blogcard'>
      <m.h1 variants={fadeIn('down', 'spring', 0.3, 1.1)}>Projects</m.h1>
      <div className='blogcards__container'>
        <m.div 
        variants={fadeIn('up', 'spring', 0.3, 1.1)}
        className='blogcards__wrapper'>

<ul 
        className='blogcards__items'>

          <Constants
              src='images/cp.png'
              text='Ceylon Palmers Pvt Ltd.'
              label='WordPress'
              label2='Portfolio'
              path='http://ceylonpalmers.com/'
              para="Portfolio website for ceylonpalmers.Ceylon Palmers (Pvt) Ltd is an 
              organic certified manufacturer and exporter of coconuts and coconut edible 
              products, owned by Mr. Muhad Ramzan."
            />
            
            <Constants
              src='images/subp.png'
              text='DD Car Detailing'
              label='WordPress'
              label2='Portfolio'
              path='https://sub.ddcardetailing.com.au/'
              para="Redesigned the DD Car Detailing website as a freelance project. 
              This comprehensive overhaul aimed to enhance their online presence, 
              resulting in a modern, responsive, and user-friendly design."
            />


          </ul>

        <ul 
        className='blogcards__items'>

          
          
          <Constants
              src='images/perk.png'
              text='Sample Site for "The Central Perk"'
              label='ReactAPP'
              label2='API'
              path='https://meekpuppy.netlify.app/'
              para='Single page website that i designed for "The Central Perk", using React.js and Tailwind CSS. 
              To animate this, i used Framer Motion.'
            />

          <Constants
              src='images/rolling.png'
              text='Rolling Dreams'
              label='WordPress'
              label2='WooCommerce'
              path='http://rollingdreams.unaux.com/'
              para="Please note that since I am using free hosting for this site, it may take forever to load. 
              This website showcases a sample WooCommerce implementation, designed with the WordPress platform and the Divi theme with Woo Commerce functions.
              Browse through the rolling accessories available."
          />
            
          <Constants
              src='images/bill.png'
              text='Electricity Bill Calculator'
              label='React APP'
              label2='API'
              path='https://elecbillcalc.netlify.app/'
              para="A simple API that helps users estimate their monthly electricity 
              bill based on the number of units consumed, using React.js and Tailwind CSS."
            />

          </ul>
         
          <ul 
          className='blogcards__items'>

          <Constants
              src='images/da.png'
              text='designambience.lk'
              label='WordPress'
              label2='WooCommerce'
              path='https://designambience.lk/'
              para="Woo Commerce Website for Interion Design Company with WooCommerce 
              functions and still working on this project. Designambience is interion 
              design company owned by my good friend Mohamed Wazeem."
            />

            <Constants
              src='images/tetris.png'
              text='Tetris Game'
              label='ReactAPP'
              label2='Game'
              path='https://tetris-gamevl.netlify.app/'
              para="Enjoy this online version of Tetris 
              that I recently developed, using Reactjs. 
              Tetris is a timeless puzzle game that challenges your spatial 
              awareness, reaction time, and strategic thinking. It's simple yet addictive, 
              and it's a great way to pass the time or unwind after a long day."
            />

          <Constants
              src='images/coco.png'
              text='cocopeatsoil.com'
              label='Wordpress'
              label2='Portfolio'
              path='https://www.cocopeatsoil.com/'
              para="Simple Portfolio Website for Coco Peat and Coir products Manufacture and Export 
              Company - cocopeatsoil Byron Lanka is a Company that exports manufacture high quality, 
              environmentally friendly Coco Peat and coir products in Sri Lanka, owned by Mr. Anthony Galgamu."
            />

          </ul>
        </m.div>
      </div>
    </m.div>
  );
}

export default Projects;