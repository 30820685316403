import React from 'react';
import './Testimonial.css';
import { Testimonialdata } from './Testimonialdata';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";

import SwiperCore, { Autoplay } from 'swiper';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

SwiperCore.use([Autoplay]);

const Testimonial = () => {
  return (
    <>
    <m.section 
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className='testimonial-container-section'>
        <m.h2 variants={fadeIn('down', 'spring', 0.3, 1.1)} className="section__title">Testimonial</m.h2>
        
        <Swiper className="testimonial__container"
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        loop={true}
        grabCursor={true}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        >
            {Testimonialdata.map(({id, image, title, description}) => {
                return (
                    <SwiperSlide className='testimonial__card' key={id}>
                        <img src={image} alt='' className='testimonial__img'/>
                        
                        <h3 className="testimonial__name">{title}</h3>
                        <p className="testimonial__description">{description}</p>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    </m.section>
    </>
  )
}

export default Testimonial