import React from 'react';
import './Cards.css';
import Carditem from './Carditem';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

function Cards() {
  return (
    <m.div 
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className='cards'>
      <m.h1 variants={fadeIn('down', 'spring', 0.3, 1.1)}>Recent Projects</m.h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>

        <m.ul 
        variants={fadeIn('right', 'tween', 0.4, 1.1)}
        className='cards__items'>
            <Carditem
              src='images/perk.png'
              text='Sample Site for "The Central Perk"'
              label='React APP'
              label2='API'
              path='https://meekpuppy.netlify.app/'
            />
            <Carditem
              src='images/cp.png'
              text='Ceylon Palmers Pvt Ltd.'
              label='WordPress'
              label2='Portfolio'
              path='http://ceylonpalmers.com/'
            />
          </m.ul>
         
          <m.ul 
          variants={fadeIn('left', 'tween', 0.5, 1.1)}
          className='cards__items'>
            <Carditem
              src='images/rolling.png'
              text='Rolling Dreams'
              label='WordPress'
              label2='WooCommerce'
              path='http://rollingdreams.unaux.com/'
            />
            <Carditem
              src='images/da.png'
              text='designambience.lk'
              label='WordPress'
              label2='WooCommerce'
              path='https://designambience.lk/'
            />
            <Carditem
              src='images/coco.png'
              text='cocopeatsoil.com'
              label='WordPress'
              label2='Portfolio'
              path='https://www.cocopeatsoil.com/'
            />
          </m.ul>
        </div>
      </div>
    </m.div>
  );
}

export default Cards;