import React from 'react';
import '../../App.css';
import Myworksection from '../Myworksection';
import Projects from '../Projects';


function Mywork (){
    return(
        <>
            <Myworksection />
            <Projects />
        </>
    );
}

export default Mywork;