import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Services</h2>
            <Link>WordPress Developing</Link>
            <Link>WooCommerce/LMS</Link>
            <Link>Portfolio</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Get in touch</h2>
            <a href="mailto:vidura112@gmail.com">vidura112@gmail.com</a>
            <a href="mailto:vidz123@outlook.com">vidz123@outlook.com</a>
            <a href="tel:+94716418542">+94 716 418 542</a>
             
          </div>
        </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <a href="https://www.facebook.com/vlochana/"><i className='fab fa-facebook' />Facebook</a>
            <a href="https://www.instagram.com/_bunny98_/"><i className='fab fa-instagram' />Instagram</a>
            <a href="https://www.linkedin.com/in/viduralochana"><i className='fab fa-linkedin' />linkedin</a>
          </div>
      </div>
        <div className='social-media-wrap'>
          <small className='website-rights'>© 2023 | All rights reserved | Designed by <a href="https://vlochana-portfolio.netlify.app/">Vidura Lochana</a> With <span>❤️</span></small>
        </div>
    </div>
  );
}

export default Footer;