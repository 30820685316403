import React from 'react';
import '../App.css';
import './Contactsection.css';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

function Contactsection() {
  return (
    <m.div 
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className='Contactsection-container'>
        <video src='/videos/video-4.mp4' autoPlay loop muted />
        <m.h1 variants={fadeIn('down', 'spring', 0.3, 1.1)}>Contact</m.h1>
        <m.p variants={fadeIn('right', 'tween', 0.4, 1.6)}>If you have any questions or queries, feel free to drop me a message. I will get back to you as soon as possible. :p <br/> Currently I'm looking for new opportunities and, my inbox is always open fou you.</m.p> 
    </m.div>
  );
}

export default Contactsection;