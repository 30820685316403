import React from 'react';
import {AiFillCaretRight} from 'react-icons/ai';

function Constants(props) {

  const isExternalLink = /^https?:\/\//.test(props.path);
  const linkProps = {
    className: 'blogcards__item__link',
    href: props.path,
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  if (isExternalLink) {
    return (
      <li className='blogcards__item'>
        <a {...linkProps}>
          <figure className='blogcards__item__pic-wrap'>
            <img
              className='blogcards__item__img'
              alt='#'
              src={props.src}
            />

            <div className='fig__cap'>
              <figcaption className='fc1'>{props.label}</figcaption>
              <figcaption className='fc2'>{props.label2}</figcaption>
            </div>
            
          </figure>
          <div className='blogcards__item__info'>
            <h5 className='blogcards__item__text'>{props.text} 
            <AiFillCaretRight className='blogcards__item__icon'/></h5>
            <p className='blogcards__item__para'>{props.para}</p>
          </div>
        </a>
      </li>
    );
  }
}

export default Constants;