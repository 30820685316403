import Image1 from '../testimonialimg/timg1.jpg';
import Image2 from '../testimonialimg/timg2.jpg';
import Image3 from '../testimonialimg/timg3.jpg';
import Image4 from '../testimonialimg/timg4.jpg';
import Image5 from '../testimonialimg/timg5.jpg';


export const Testimonialdata = [
    {
      id: 1,
      image: Image1,
      title: "Jhon Doe",
      description: "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
      id: 2,
      image: Image2,
      title: "Harry Clinton",
      description: "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
      id: 3,
      image: Image3,
      title: "Raya Harper",
      description: "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
      id: 4,
      image: Image4,
      title: "Wednesday Eleanor",
      description: "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
      id: 5,
      image: Image5,
      title: "Ceylon Palmers Pvt Ltd",
      description: "well recommended company with aftercare. 24x7 excellent service. Good Luck and best wishes.",
    },
  ];