import React from 'react';
import '../../App.css';
import Contactform from '../Contactform';
import Contactsection from '../Contactsection';



function Contact (){
    return(
        <>
            <Contactsection />
            <Contactform />
        </>
    );
}

export default Contact;