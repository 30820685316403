import React from 'react';
import '../App.css';
import './Myworksection.css';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

function Myworksection() {
  return (
    <m.div 
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className='section-container'>
        <m.h1 variants={fadeIn('down', 'spring', 0.3, 1.1)}>My work</m.h1>
        <m.p variants={fadeIn('right', 'tween', 0.4, 1.6)}>Here, you will find a collection of my professional endeavors  
          and accomplishments. As an experienced web developer,<br/>
          I have a passion for delivering high-quality work that exceeds expectations and, 
          I'm excited to showcase my work and share my insights with you. </m.p>
    </m.div>
  );
}

export default Myworksection;