import React from 'react';
import './Faq.css';
import {
  FaBalanceScale,
  FaGlobeAsia, 
  FaShippingFast,
  FaPaintBrush, 
  FaUserAlt, 
} from 'react-icons/fa';
import {BsFillPhoneFill} from 'react-icons/bs'
import { IconContext } from 'react-icons';
import '../App.css';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer, fatrans } from './variants';


const Faq = () => {
  return (
    <>
    <m.section 
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className="faq">
      <div className="tittle">
        <m.h2 variants={fadeIn('down', 'spring', 0.3, 1.1)}>Why choose me :)</m.h2>
      </div>

      <m.div className="faq-row">
        <IconContext.Provider value={{size:'4rem' , color:'#ffff'}}>
        <div className="faq-col">
          <m.div 
          variants={fatrans('','spring', 0.4, 1.1)}
          className="single-faq">
            <span className="icon-area"><FaUserAlt /></span>
            <div className="faq-content">
              <h2>Guaranteed client satisfaction</h2>
              <p>In web design, guaranteeing client satisfaction can be a challenging task. 
                It requires a clear understanding of the client's requirements and expectations, 
                effective communication, and delivering a high-quality end product that 
                meets those requirements.</p>
            </div>
          </m.div>
        </div>

        <div className="faq-col">
          <m.div 
          variants={fatrans('','spring', 0.5, 1.1)}
          className="single-faq">
            <span className="icon-area"><FaGlobeAsia /></span>
            <div className="faq-content">
              <h2>24/7 communication support</h2>
              <p>24/7 communication support can be a valuable feature in web design, 
                as it provides clients with the peace of mind that they can reach out to 
                the design team at any time. This is especially important for clients 
                who are based in different time zones or have urgent needs.</p>
            </div>
          </m.div>
        </div>

        <div className="faq-col">
          <m.div 
          variants={fatrans('','spring', 0.6, 1.1)}
          className="single-faq">
            <span className="icon-area"><BsFillPhoneFill /></span>
            <div className="faq-content">
              <h2>Responsive design</h2>
              <p>Responsive design is a web design approach that enables a website to 
                adjust its layout and content to different screen sizes and devices. 
                This means that the website will look good and be easy to use on any device, 
                whether it's a desktop computer, tablet, or smartphone.</p>
            </div>
          </m.div>
        </div>

        <div className="faq-col">
          <m.div 
          variants={fatrans('','spring', 0.7, 1.1)}
          className="single-faq">
            <span className="icon-area"><FaShippingFast /></span>
            <div className="faq-content">
              <h2>Perfect time management</h2>
              <p>Time management is an essential aspect of web design, as it helps to ensure 
                that projects are completed on time and within budget. This can lead to more 
                satisfied clients, as projects are completed on time and within budget.</p>
            </div>
          </m.div>
        </div>

        <div className="faq-col">
          <m.div 
          variants={fatrans('','spring', 0.8, 1.1)}
          className="single-faq">
            <span className="icon-area"><FaPaintBrush /></span>
            <div className="faq-content">
              <h2>Modern and creative design</h2>
              <p>Modern and creative design is a key aspect of web design, as it helps to make 
                websites visually appealing and memorable. By creating modern and creative designs 
                that engage and delight their target audience, can lead to a better user experience, 
                increased conversions, and a more memorable brand.</p>
            </div>
          </m.div>
        </div>

        <div className="faq-col">
          <m.div 
          variants={fatrans('','spring', 0.9, 1.1)}
          className="single-faq">
            <span className="icon-area"><FaBalanceScale /></span>
            <div className="faq-content">
              <h2>Client understanding</h2>
              <p>Client understanding is an essential aspect of web design, as it 
                helps to ensure that the final product meets the client's needs 
                and expectations. Web designers can achieve a deep understanding 
                of the client's needs and preferences, which can lead to a better 
                product, a stronger working relationship, and increased client satisfaction.</p>
            </div>
          </m.div>
        </div>
        </IconContext.Provider>
      </m.div>
    </m.section>
    </>
  );
}

export default Faq;