import React from 'react';
import {AiFillCaretRight} from 'react-icons/ai';

function CardItem(props) {

  const isExternalLink = /^https?:\/\//.test(props.path);
  const linkProps = {
    className: 'cards__item__link',
    href: props.path,
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  if (isExternalLink) {
    return (
      <li className='cards__item'>
        <a {...linkProps}>
          <figure className='cards__item__pic-wrap'>
            <img
              className='cards__item__img'
              alt='#'
              src={props.src}
            />
            <div className='fig__cap'>
              <figcaption className='fc1'>{props.label}</figcaption>
              <figcaption className='fc2'>{props.label2}</figcaption>
            </div>
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text} 
            <AiFillCaretRight className='cards__item__icon'/></h5>
          </div>
        </a>
      </li>
    );
  }
}

export default CardItem;