import React from 'react';
import '../App.css';
import './Aboutfooter.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

function Aboutfooter() {
  return (
    <m.div 
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className='Aboutfooter-container'>
        <m.h3 variants={fadeIn('down', 'tween', 0.4, 1.3)} className='Aboutfooter_topic'>Let's talk</m.h3>
        <m.p variants={fadeIn('down', 'tween', 0.5, 1.3)}className='para'>Wanna get in touch or talk about a project? <br/>
        feel free to contact me via email at <a className='abtlink' href="mailto:vidz123@outlook.com">vidz123@outlook.com</a> <br/>
        or fill up the form at the <Link className='abtlink' to='/contact' >Contact Page</Link> and drop a message 😜<br/>Cheers</m.p>
        <Button link='/Wussup' buttonSize='btn--donot'>Hit me for something funny</Button>
    </m.div>
  );
}

export default Aboutfooter;