import React from 'react';
import '../App.css';
import './AboutSection.css';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

function AboutSection() {
  return (
    <m.div 
    variants={staggerContainer(0.3, 1)}
    initial='hidden'
    whileInView={'show'}
    className='about-container'>
      <img className='asbg' src='/images/img-7.jpg' alt='' />
        <m.h1 variants={fadeIn('down', 'spring', 0.3, 1.1)}>About</m.h1>
        <m.p variants={fadeIn('right', 'tween', 0.4, 1.6)}>Born 1998, Sri Lankan. <br/>I play games when I'm bored :p <br/>It is not just websites that i design.</m.p>
    </m.div>
  );
}

export default AboutSection;