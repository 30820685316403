import React,{useState} from 'react';
import '../App.css';
import './Aboutcol.css';

import { motion as m } from 'framer-motion';
import { fadeIn, staggerContainer } from './variants';

function Aboutcol() {
  const[toggleTab, setToggleTab] = useState (1)
  const toggleState = (index) =>{
    setToggleTab(index)
  }
  return (
    <>
      <m.section 
      variants={staggerContainer(0.3, 1)}
      initial='hidden'
      whileInView={'show'}
      className='about-description'>
        <div className='row'>
          <div className="column">
            <m.div 
            variants={fadeIn('right', 'spring', 0.3, 1.1)}
            className="about-img"></m.div>
          </div>

          <div className="column">

            <m.div 
            variants={fadeIn('down', 'tween', 0.3, 1.4)}
            className="tabs">
              <div className={toggleTab === 1 ? "single-tab active-tab" : "single-tab"}
              onClick = {()=> toggleState(1)}
              >
                <h2>About</h2>
              </div>
              <div className={toggleTab === 2 ? "single-tab active-tab" : "single-tab"}
              onClick = {()=> toggleState(2)}
              >
                <h2>Skills</h2>
              </div>
              <div className={toggleTab === 3 ? "single-tab active-tab" : "single-tab"}
              onClick = {()=> toggleState(3)}
              >
                <h2>Experience</h2>
              </div>
              <div className={toggleTab === 4 ? "single-tab active-tab" : "single-tab"}
              onClick = {()=> toggleState(4)}
              >
                <h2>Education</h2>
              </div>
            </m.div>

            <div className="tab-content">
              {/* About Content*/}

              <div className={toggleTab === 1 ?"content active-content":"content"}>
                <m.h2 variants={fadeIn('down', 'tween', 0.5, 1.3)}>My Story</m.h2>
                <m.p variants={fadeIn('down', 'tween', 0.6, 1.3)}>Hello! My name is Vidura Lochana and I'm from Sri Lanka. I enjoy developing websites and 
                  I help small businesses to go online. My interest in web development started back in 2018
                   after i finished my diploma in information and communication technology. While i was
                    compleating my higher national diploma and bachelor degree, i have learnt so many
                     things related to web development, new technologies such as react js and creative coding.</m.p>
                  <m.h3 variants={fadeIn('up', 'tween', 0.5, 1.3)}>I am a web designer and developer</m.h3>
                  <m.p variants={fadeIn('up', 'tween', 0.6, 1.3)}>As I mentioned before, I design and develop wordpress websites for small 
                    businesses, medium size web apps, ecommerce websites, portfolio websites and EMS 
                    web sites with Divi theme and Elementor and create dynamic user experiences.</m.p>
              </div>

              {/* Skill content */}

              <div className={toggleTab === 2 ?"content active-content":"content"}>
                <m.h2 variants={fadeIn('down', 'tween', 0.5, 1.3)}>Skills</m.h2>
                <m.p variants={fadeIn('down', 'tween', 0.6, 1.3)}>I'm passionate about web development and front-end development and, HTML, CSS, PHP, and
                   WordPress are my main expertise. I always try to do my best to make the project pixel-perfect. 
                   And I always try to learn new programming languages and am willing to learn new things to improve my skills.</m.p>

                  <div className="skills-row">
                    <div className="skills-column">
                      <div className="progress-wrap">
                        <h3>Wordpress </h3>
                        <div className="progress">
                          <div className="progress-bar">
                            <span>80%</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="skills-column">
                      <div className="progress-wrap">
                        <h3>CSS</h3>
                        <div className="progress">
                          <div className="progress-bar css">
                            <span>80%</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="skills-column">
                      <div className="progress-wrap">
                        <h3>Java Script</h3>
                        <div className="progress">
                          <div className="progress-bar java"> 
                            <span>60%</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="skills-column">
                      <div className="progress-wrap">
                        <h3>TailwindCSS </h3>
                        <div className="progress">
                          <div className="progress-bar tailwind"> 
                            <span>70%</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="skills-column">
                      <div className="progress-wrap">
                        <h3>REACT JS</h3>
                        <div className="progress">
                          <div className="progress-bar react">
                            <span>60%</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="skills-column">
                      <div className="progress-wrap">
                        <h3>SEO</h3>
                        <div className="progress">
                          <div className="progress-bar seo">
                            <span>60%</span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
              </div>

              {/* Experience Content*/}

              <div className={toggleTab === 3 ?"content active-content":"content"}>
                <div className="exp-column">
                  <h3>Web Designer/Developer</h3>
                  <span>Jan 2020 - present</span>
                  <p>WordPress Web design/Development with Divi theme and Elementor<br/>UI design</p>
                </div>

                <div className="exp-column">
                  <h3>Freelancer</h3>
                  <span>Jan 2020 - present</span>
                  <p>WordPress Web design/Development<br/>Flyers, YouTube banners, Social media post 
                  designing using Adobe Photoshop and Canva</p>
                </div>

                <div className="exp-column">
                  <h3>Event Coordinator/DJ</h3>
                  <span>Jan 2017 - Feb 2019</span>
                  <p>Coordinated major events with music, sounds & 
                    lightings<br/>Spin Worx Entertainment</p>
                </div>
              </div>

              {/* Education Content*/}

              <div className={toggleTab === 4 ?"content active-content":"content"}>
                <div className="exp-column">
                  <h3>BSc in Software Engineering (CMU) (Old)</h3>
                  <span>2020 - 2022</span>
                </div>

                <div className="exp-column">
                  <h3>Higher National Diploma in Computing and Software Engineering</h3>
                  <span>2019</span>
                </div>

                <div className="exp-column">
                  <h3>Diploma in Information & Communication Technology</h3>
                  <span>2017</span> 
                </div>
              </div>

            </div>

          </div>
        </div>
      </m.section>
    </>
  );
}

export default Aboutcol;