import React from 'react';
import '../../App.css';
import AboutSection from '../AboutSection';
import Aboutcol from '../Aboutcol';
import Aboutfooter from '../Aboutfooter';

function About (){
    return(
        <>
            <AboutSection />
            <Aboutcol />
            <Aboutfooter />
        </>
    );
}

export default About;